import React from "react";

import Seo from "../components/Seo";
import "../style/uebermich.scss";
import { StaticImage } from "gatsby-plugin-image";
import { BsFileRichtext } from "react-icons/bs";

const Bio = () => {
  return (
    <>
      <Seo title="Über mich" />
      <div className="card">
        <div className="center">
          <div className="card-top">
            <h1>
              <BsFileRichtext /> Biografie
            </h1>
          </div>
          <div className=" card-content">
            <figure>
              <picture>
                <StaticImage
                  className="carousel-img"
                  src="../images/carsten-grüter-desktop.jpg"
                  alt="Foto Carsten Grüter"
                  fit="cover"
                  placeholder="blurred"
                />
              </picture>

              <figcaption>
                <a
                  className="topel"
                  href="https://myriamtopel.de"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  &copy; Myriam Topel Fotografie
                </a>
              </figcaption>
            </figure>

            <p>
              <span className="bold">
                {" "}
                <span className="drumup"> Drum UP</span> – Carsten Grüter
              </span>
            </p>

            <div className="bio-text">
              <p>
                Der Schlagzeuger und Dozent Carsten Grüter, Geboren 1977 in
                Mönchengladbach, fängt die Musikerkarriere früh an, als er schon
                als Baby den Klängen seiner Eltern am Saxophon und Bass lauschen
                darf. <br></br> Mit 3 Jahren entdeckt er die Welt der Töne auf
                seinem ersten Keyboard und mit 6 muss dann das erste Schlagzeug
                dran glauben. Schon kurz darauf folgen erste Auftritte mit dem
                Blasorchester der Eltern.
              </p>
              <p>1983 – 1989</p>
              <p>Schlagzeugunterricht bei Heinz Dittmann</p>
              <p>1991 – 1999</p>
              <p>
                Musikschule Mönchengladbach (Nicky Gebhart, Robert Hurasky),
                Klavier (Klaus Delvos, Herr Klein) und Musiktheorie
              </p>
              <p>1996 – 2000</p>
              <p>
                Auch die Weltmusik hat es Carsten angetan. Die indische Tabla
                wird bei Raimund Engelhardt studiert.
              </p>
              <p>1999 – 2002</p>
              <p>
                Es folgt dann was folgen muss, das Schlagzeugstudium in
                Arnheim(NL) bei Rene Creemers und Joop van Erwen.
              </p>
              <p>Seit 2002</p>
              <p>
                Als Schlagzeuger ist sowohl die Welt des Jazz, vom Trio bis zur
                BigBand, als auch die Rock- und Pop Musik spannend. Viele Bands
                und Projekte touren durch die Lande, u.a. Stadtgeflüster und
                Fresh als Coverbands
              </p>
              <p>Seit 1995</p>
              <p>
                Das Unterrichten begann Carsten schon als Schüler mit viel
                Engagement und sammelte genug Erfahrung um sich auch beruflich
                hier ein Standbein aufzubauen.
              </p>
              <p>1998 – 2020</p>
              <p>
                Dozent für Schlagzeug in der Musikschule Groove in
                Mönchengladbach.
              </p>
              <p>2008</p>
              <p>
                Tontechnik Fernstudium mit Abschluss als Audioengineer bei den
                Hofa Studios. Seit dem sind Produktionen für Messen,
                Firmenevents, aber auch für Bands und Solokünstler entstanden
              </p>
              <p>Seit 2010</p>
              <p>
                Ein besondere Liebe gilt dem Samba. Bands u.a. Rio Reno mit Kay
                Vester, mit vielen nationalen und internationalen Auftritten
                z.B. beim Prag Marathon, Hochzeiten in der Türkei, Boarder Week
                in Val Thorens, Marathon auf Antiparos, Hop Top Show in Bern …
              </p>
              <p>Seit 2013</p>

              <p> Leitung der Samba Band „Somedrums“</p>
              <p>seit 2015</p>
              <p>
                Aus den Erfahrungen in Bands und dem Unterrichten hat sich das
                Teamtraining mit Trommeln entwickelt, das regelmäßig von
                Unternehmen, aber auch Kleingruppen gebucht wird.
              </p>
              <p>seit 2016</p>
              <p>
                Dozent im Kultur und Schule Programm der NRW Landesregierung an
                vielen Grundschulen, bei Bandworkshops, u.a. regelmäßig beim
                Sinfonischen Rock-Orchester mit David Koebele
              </p>

              <p>seit 2020</p>
              <p>freiberuflicher Unterricht in der RedBox in Mönchengladbach</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bio;
